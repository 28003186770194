import React from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry from 'react-masonry-css'
import '../styles/Units.css';

const HeadingContainer = tw.div `text-center`;
const ChildrenContainer = tw.div `text-center my-10`;
const PostImage = styled(LazyLoadImage)
`
  ${tw`rounded-md m-2`}
`;
const Container = styled(ContainerBase)
`
    ${tw`px-6 pb-24`}
`;
const DecoratorBlob1 = tw(SvgDotPatternIcon)`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-primary-100 opacity-50`
const DecoratorBlob2 = tw(SvgDotPatternIcon)`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-primary-100 opacity-50`

const breakpointColumnsObj = {
  default: 3,
  900: 2,
  450: 1
};

//eslint-disable-next-line
export default (props) => {
    return (
        <Container>
            {/* <ContentWithPaddingXl> */}
                <HeadingContainer>
                    {props.heading}
                </HeadingContainer>
                {props.children && (
                  <ChildrenContainer>
                    {props.children}
                  </ChildrenContainer>
                )}
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                  >
                    {props.posts.map((post, index) => (
                    // <FlexCell>
                      <PostImage key={index} src={post.imageSrc} alt={post.imageAlt} />
                    // </FlexCell>
                    ))}
                    <DecoratorBlob1 />
                    <DecoratorBlob2 />
                  </Masonry>
            {/* </ContentWithPaddingXl> */}
        </Container>
    );
};