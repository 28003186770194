import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logos/bulls2_transp.png";
import { Link as ReactLink } from 'react-router-dom';

import { MdFacebook as FacebookIcon } from 'react-icons/md';
import { FiInstagram as InstagramIcon } from 'react-icons/fi';


const Container = tw(ContainerBase)
`bg-black text-gray-100 -mx-8 -mb-8`;

const Content = tw.div `max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div `flex items-center justify-center flex-col px-8`

const LogoContainer = tw(ReactLink)
`
  flex items-center justify-center md:justify-start mx-auto
`;

const LogoImg = tw.img `w-20`;

const LogoText = tw.h5 `ml-2 text-2xl font-black tracking-wider text-center`;

const LinksContainer = tw.div `mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`

const Link = tw(ReactLink)
`border-b-2 border-transparent hocus:text-gray-300 hocus:border-primary-100 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div `mt-10`;

const SocialLink = styled.a `
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-primary-100 transition duration-300 mx-4`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

export default (props) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer to="/">
            <LogoImg src={logo} />
            <LogoText>BULLS BEER HOUSE</LogoText>
          </LogoContainer>
          <LinksContainer>
            {props.navLinks && props.navLinks.map((link, i) => {
              let linkProps = {
                to: link.href,
              };
      
              if (link.hasOwnProperty('external') && link.external === true){
                linkProps.to = {
                  pathname: linkProps.to
                };
              }
      
              if (link.hasOwnProperty('newtab') && link.newtab === true){
                linkProps.target = '_blank';
                linkProps.rel = 'noopener noreferrer';
              }

              return (
                <Link {...linkProps} key={i}>
                  {link.text}
                </Link>
            )})}
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com/bullsbeerhouse/" target='_blank' rel='noopener noreferrer'>
              <FacebookIcon size={50} />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/bullsbeerhouse/" target='_blank' rel='noopener noreferrer'>
              <InstagramIcon size={50} />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2022, Bulls Beer House. Todos os direitos reservados.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};