import React, { useRef, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";

// import HomeVideoThumbTest from "images/rooms/aldeota/foto07_3.jpg";

import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js"; //eslint-disable-line

const HomeVideo = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/videos/bulls_video_home_desktop_ipad.mp4";
// const HomeVideoMobile1 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/bulls-general/videos/bulls_home_video1_mobile.mp4";
// const HomeVideoMobile2 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/bulls-general/videos/bulls_home_video2_mobile.mp4";
const HomeVideoThumb = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/videos/bulls_home_thumb_new.jpg";

// const StyledHeader = styled(Header)
// `
//   ${tw`pt-8 max-w-none w-full`}
//   font-family: Inter,system-ui;
//   ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
//     ${tw`text-white hover:border-primary-100 hover:text-gray-300`}
//   }
//   ${NavToggle}.closed {
//     ${tw`text-gray-100 hover:text-primary-500`}
//   }
// `;

const Container = styled.div`
  ${tw`relative -mx-4 -mt-4 bg-center bg-cover h-screen min-h-144 bg-black`}
`;

const OpacityOverlay = tw.div`z-20 absolute inset-0 bg-black opacity-50`;
const VideoContainer = tw.div`z-10 absolute inset-0 min-h-screen max-h-screen text-center overflow-hidden bg-black`;
const VideoComponent = tw.video`h-full w-full object-cover bg-black`;
const VideoPoster = tw.img`h-full w-full object-cover`;

const HeroContainer = tw.div`z-30 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
// const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

// const Heading = styled.h1`
//   ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 -mb-40 sm:mt-0`}
//   span {
//     ${tw`inline-block mt-2`}
//   }
// `;

//eslint-disable-next-line
export default (props) => {
  // const navLinks= [
  //   <NavLinks key={1}>
  //     {props.navLinks && props.navLinks.map((link, i) => {
  //       let linkProps = {
  //         to: link.href,
  //       };

  //       if (link.hasOwnProperty('external') && link.external === true){
  //         linkProps.to = {
  //           pathname: linkProps.to
  //         };
  //       }

  //       if (link.hasOwnProperty('newtab') && link.newtab === true){
  //         linkProps.target = '_blank';
  //         linkProps.rel = 'noopener noreferrer';
  //       }

  //       return (
  //         <NavLink {...linkProps} key={i}>
  //           {link.text}
  //         </NavLink>
  //     )})}
  //   </NavLinks>
  // ];

  const [isIOS, setIOS] = useState(null);
  const vComp = useRef(null);

  useEffect(() => {
    if(isIOS === null){
      function isSafari() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0
      }
  
      if(isSafari()){
        setIOS(true);
      } else {
        setIOS(false);
      }
    }

    return null;
  }, [isIOS]);

  return (
    <Container>
      <VideoContainer>
        {isIOS ? (<VideoPoster src={HomeVideoThumb} />) : (<VideoComponent 
          ref={vComp} 
          autoPlay={ isIOS ? false : true } 
          playsInline={ isIOS ? false : true } 
          preload={ isIOS ? "" : "metadata" } 
          loop 
          muted 
          disablePictureInPicture 
          poster={HomeVideoThumb}
        >
          <source src={HomeVideo} type="video/mp4"/>
        </VideoComponent>)}
      </VideoContainer>
      <OpacityOverlay />
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        {/* <Content>
          <Heading>
              BULLS BEER HOUSE
          </Heading>
          <PrimaryAction>Search Events Near Me</PrimaryAction>
        </Content> */}
      </HeroContainer>
    </Container>
  );
};