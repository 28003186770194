import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import routes from "Routes.js";

import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import LandingPage from "BullsLandingPage";
import PageNotFound from "pages/404";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <LandingPage routes={routes} section="" />} />
        <Route path="/sobre" render={() => <LandingPage routes={routes} section="sobre" />} />
        <Route path="/cardapio" render={() => <LandingPage routes={routes} section="cardapio" />} />
        <Route path="/ambientes" render={() => <LandingPage routes={routes} section="ambientes" />} />
        <Route path="/contato" render={() => <LandingPage routes={routes} section="contato" />} /> 
        <Route path="/pesquisa" component={() => { 
            window.location.href = 'http://pesquisa.bullsbeerhouse.com/survey'; 
            return null;
        }}/>
        <Route path="/survey" component={() => { 
            window.location.href = 'http://pesquisa.bullsbeerhouse.com/survey'; 
            return null;
        }}/>
        <Route render={() => <PageNotFound routes={routes} />} />
      </Switch>
    </Router>
  );
}
