export default [{
        href: "/",
        text: "Início"
    },
    {
        href: "/sobre",
        text: "Sobre o Bulls"
    },
    {
        href: "/cardapio",
        text: "Cardápio"
    },
    {
        href: "/ambientes",
        text: "Ambientes"
    },
    {
        href: "/contato",
        text: "Fale Conosco"
    },
    // {
    //     newtab: true,
    //     href: "/pesquisa",
    //     text: "Pesquisa de Satisfação"
    // }
];