// Aldeota

const aldeota_img01 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto01.jpeg"
const aldeota_img03 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto03.jpeg"
const aldeota_img04 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto04.jpeg"
const aldeota_img05 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto05.jpeg"
const aldeota_img06 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto06.jpeg"
const aldeota_img07 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto07.jpg"
const aldeota_img08 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto08.jpg"
const aldeota_img09 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto09.jpg"
const aldeota_img10 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto10.jpg"
const aldeota_img11 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto11.jpg"
const aldeota_img12 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/aldeota/resized+and+compressed/foto12.jpg"

// Parquelandia

const parquelandia_img01 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto01.jpeg"
const parquelandia_img02 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto02.jpeg"
const parquelandia_img03 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto03.jpg"
const parquelandia_img04 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto04.jpg"
const parquelandia_img05 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto05.jpg"
const parquelandia_img06 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto06.jpg"
const parquelandia_img07 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto07.jpg"
const parquelandia_img10 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto10.jpg"
const parquelandia_img11 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/compressed+and+resized/foto11.jpg"

const images = {
    aldeota: [
        { imageSrc: aldeota_img01, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img03, imageAlt: "Espaço Bulls Aldeota", orientation: 'landscape' },
        { imageSrc: aldeota_img04, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img05, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img06, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img07, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img08, imageAlt: "Espaço Bulls Aldeota", orientation: 'landscape' },
        { imageSrc: aldeota_img09, imageAlt: "Espaço Bulls Aldeota", orientation: 'landscape' },
        { imageSrc: aldeota_img10, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img11, imageAlt: "Espaço Bulls Aldeota" },
        { imageSrc: aldeota_img12, imageAlt: "Espaço Bulls Aldeota" }
    ],
    parquelandia: [
        { imageSrc: parquelandia_img01, imageAlt: "Espaço Bulls Parquelandia", orientation: 'landscape' },
        { imageSrc: parquelandia_img02, imageAlt: "Espaço Bulls Parquelandia" },
        { imageSrc: parquelandia_img03, imageAlt: "Espaço Bulls Parquelandia" },
        { imageSrc: parquelandia_img04, imageAlt: "Espaço Bulls Parquelandia", orientation: 'landscape' },
        { imageSrc: parquelandia_img05, imageAlt: "Espaço Bulls Parquelandia", orientation: 'landscape' },
        { imageSrc: parquelandia_img06, imageAlt: "Espaço Bulls Parquelandia", orientation: 'landscape' },
        { imageSrc: parquelandia_img07, imageAlt: "Espaço Bulls Parquelandia" },
        { imageSrc: parquelandia_img10, imageAlt: "Espaço Bulls Parquelandia" },
        { imageSrc: parquelandia_img11, imageAlt: "Espaço Bulls Parquelandia" }
    ],
};

export default images;