import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import "../../styles/AboutUs.css";

import { SectionHeading as Heading } from "components/misc/Headings.js";

const HEAD1 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/rooms/parquelandia/foto01.jpeg";
const HEAD2 = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/headings/about_us_2.jpg";

const Container = styled.div `
  ${tw`mt-4 max-w-screen-xl text-xl text-gray-100 lg:mx-auto overflow-hidden`}
`;

const ImageComponent = styled.img`
  ${tw`rounded-lg mb-5`}
  object-fit: cover;
  max-height: 250px;
  width: 100%;
`;

const TextContainer = styled.p`
  ${tw`p-4 text-gray-100 font-mono text-justify text-base shadow-2xl`}
  font-family: 'Raleway', sans-serif;
  font-size: 1.2rem;
  span {
    color: red;
    font-size: 1.4rem;
    font-weight: 700;
  }
`;

const SectionHeading = styled(Heading)
`
  ${tw`text-white pb-8 text-4xl`}
`;

//eslint-disable-next-line
export default (props) => {

  return (
    <>
        <section>
          <SectionHeading>
            História
          </SectionHeading>
          <Container>
            <ImageComponent src={HEAD1} alt="Historia do Bulls Decoration" style={{objectPosition: "100% 67%"}}/>
            <TextContainer>
              O <span >Bulls Beer House</span> surgiu da
              ideia de dois amigos que amam sair para
              <span > comer</span> e
              <span > beber</span> bem, ouvir um bom
              <span > rock n’ roll</span> e são
              <span > fãs de esporte</span>.Com esse mix de
              interesses, o Bulls
              <span > surgiu em 2016 </span> como o mais
              legítimo <span > pub de Fortaleza</span>.
              <span > Começamos</span> em um imóvel bem
              apertado de 80 m² no bairro
              <span > Parquelândia</span>, mas com a rápida
              <span > adesão do público</span>, em menos de 1
              ano já fizemos nossa primeira
              <span > expansão</span>: alugamos a casa ao
              lado e fizemos um salão fechado para
              <span > bandas ao vivo</span> e
              <span > karaokê</span>. Esse
              <span > novo ambiente</span> trouxe todo um
              <span > charme</span> à casa e pessoas de todos
              os <span > lugares de Fortaleza</span> passaram
              a nos visitar para acompanhar nossa
              <span > programação</span>.  Passamos por muitas
              <span > dificuldades</span> durante a
              <span > pandemia</span>. Implantamos delivery,
              vendemos pizzas, almoço e até café da manhã para se virar nesse
              período, mas fomos recompensados. Em julho de
              <span > 2021</span>, inauguramos nossa
              <span > segunda unidade</span>, agora no bairro
              <span > Aldeota</span>.
              <span > Hoje</span> estamos com as
              <span > duas unidades</span> em pleno
              funcionamento e com <span > alta adesão </span>
              por parte da galera de <span > Fortaleza</span>
              , bem como de <span > turistas</span> que vêm
              nos <span > visitar</span>.
            </TextContainer>
          </Container>
        </section>
        <section>
          <SectionHeading>
            A Casa
          </SectionHeading>
          <Container>
            <ImageComponent src={HEAD2} alt="Historia do Bulls Decoration"/>
            <TextContainer>
              Com foco em um <span > cardápio</span> bem 
              <span > americano</span>, a casa dispõe de opções de
              <span > hambúrgueres</span>, 
              <span > cortes nobres</span>, 
              <span > petiscos</span> e pratos da 
              <span > culinária tex-mex</span>,
              como <span > costela suína</span> ao molho 
              <span > barbecue</span>, 
              <span > buffalo wings</span> e 
              <span > fajitas mexicanas</span>. Os 
              <span > drinks</span> são um show a parte. São diversas opções de
              drinks <span > clássicos</span>, 
              <span > autorais</span>, 
              <span > shots</span> e 
              <span > coquetéis coletivos</span> para dividir
              com a galera. Todo mundo <span > ama</span> os 
              <span > drinks</span> do 
              <span >Bulls</span>! Não poderia faltar
              também boas opções de <span > chopp</span> e 
              <span > cervejas</span>. Dispomos de 
              <span > chopp</span>,
              de <span > 600 ml</span> e alguns 
              <span > rótulos artesanais</span>. O pub dispõe de 
              <span > programação ao vivo</span> com muito 
              <span > rock nacional</span>, 
              <span > internacional</span>, 
              <span > indie rock</span> e 
              <span > pop</span>. Conta
              também com <span > karaokê</span> em alguns dias da semana. Transmissão de 
              <span > esportes</span>,
              como <span > futebol</span>, 
              <span > basquete</span>, 
              <span > UFC</span> e 
              <span > futebol americano</span>, também faz parte da
              <span > programação</span> da 
              <span > Bulls</span>
            </TextContainer>
          </Container>
        </section>
    </>
  );
};