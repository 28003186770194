import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as Heading } from "components/misc/Headings.js";
import { MdPictureAsPdf as BtnActIcon } from "react-icons/md";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { cardapio, cardapioLink } from "helpers/UtilLinks.js";

// Import Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/RestaurantMenuSlider.css";

const Container = styled.div`
  ${tw`z-20 relative mx-auto bg-black px-5 py-5 max-w-6xl`}
`;

const ImageContainer = styled.div`
  ${tw`container mx-auto min-w-full`}
  div {
    ${tw`mx-auto`}
  }
  img {
    ${tw`min-h-screen mx-auto w-full`}
    max-width: 520px;
  }
`;

const ActionButton = styled.button`
  ${tw`align-middle justify-center items-center text-center text-xl px-8 py-3 mx-auto mb-12 rounded bg-primary-900 text-white hocus:bg-primary-400 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}
  ${Heading} {
    ${tw`text-white text-xl inline align-middle`}
  }
`;

const ButtonIcon = styled(BtnActIcon)`
  ${tw`inline align-middle text-white`}
  * {
    stroke: white;
  }
`;

//eslint-disable-next-line
export default (props) => {

  const slider_settings = {
    // lazyLoad: 'ondemand',
    dotsClass: 'slick-slider-dots',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableHeight: true,
    // fade: true,
    // cssEase: 'ease-out',
    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
      <>
        <section style={{ textAlign: 'center' }}>
            <ActionButton
              onClick={() => window.open(cardapioLink, '_blank', 'noopener,noreferrer')}
              >
              <ButtonIcon size={25} color="white"/>
              &nbsp;&nbsp;&nbsp;
              <Heading>Visualizar Cardápio em PDF</Heading>
            </ActionButton>
            <Container>
                <Slider {...slider_settings}>
                {cardapio.map((link, index) => (
                  <ImageContainer>
                    <TransformWrapper
                    centerOnInit
                    wheel={{ disabled: true, activationKeys: ['Control', 'Shift'] }}
                    panning={{ disabled: true }}
                    >
                    <TransformComponent>
                        <img src={link} alt={"Cardápio Página " + (index+1)} />
                    </TransformComponent>
                    </TransformWrapper>
                </ImageContainer>
                ))}
                </Slider>
            </Container>
        </section>
      </>
  )
}