import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as Heading } from "components/misc/Headings.js";
import GridListImage from "helpers/GridListImage.jsx";

import { BiSearchAlt as ViewInsideIcon } from "react-icons/bi";
import RoomsImages from "helpers/BullsUnitsImages.js";

const ActionButton = styled.button`
  ${tw`align-middle text-xl px-8 py-3 rounded bg-primary-500 text-white hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}
  ${Heading} {
    ${tw`text-white text-xl inline align-middle`}
  }
`;

const InsideIcon = styled(ViewInsideIcon)`
  ${tw`inline align-middle text-white`}
  * {
    stroke: white;
  }
`;

const UnitHeading = styled(Heading)
`
  ${tw`text-white text-3xl`}
`;

const Container = styled.div`${tw`relative mx-auto px-0 max-w-screen-2xl`}`;

//eslint-disable-next-line
export default (props) => {
    return (
        <>
            <Container>
                <GridListImage 
                    posts={RoomsImages.aldeota} 
                    heading={<UnitHeading >Unidade <span tw="p-1 border-b-4 border-primary-500">Aldeota</span></UnitHeading>}
                >
                    <ActionButton
                    onClick={() => window.open('https://goo.gl/maps/qo38vJJqKSA15orcA', '_blank', 'noopener,noreferrer')}
                    >
                    <Heading>Por Dentro do Bulls Aldeota</Heading> <InsideIcon size={25} color="white"/>
                    </ActionButton>
                </GridListImage>
                <GridListImage 
                  posts={RoomsImages.parquelandia} 
                  heading={<UnitHeading >Unidade <span tw="p-1 border-b-4 border-primary-500">Parquelândia</span></UnitHeading>}
                >
                  <ActionButton
                    onClick={() => window.open('https://maps.app.goo.gl/VtrutbLLniWpc1kb8', '_blank', 'noopener,noreferrer')}
                    >
                    <Heading>Por Dentro do Bulls Parquelândia</Heading> <InsideIcon size={25} color="white"/>
                    </ActionButton>
                </GridListImage>
            </Container>
        </>
    )
}