const instagram = "https://www.instagram.com/bullsbeerhouse/";
const facebook = "https://www.facebook.com/@bullsbeerhouse/";
const whatsapp = "https://wa.me/5585991525710?text=Ol%C3%A1.%20Vim%20pelo%20site%20do%20Bulls.%20Gostaria%20de%20tirar%20uma%20d%C3%BAvida.";
const reserve = "https://wa.me/5585991525710?text=Ol%C3%A1.%20Vim%20pelo%20site%20do%20Bulls.%20Gostaria%20de%20efetuar%20uma%20reserva.";
const site = "https://bullsbeerhouse.com/"
const unitsAddress = {
    parquelandia: "https://maps.app.goo.gl/3UWEipRKmYDhNg5cA",
    aldeota: "https://maps.app.goo.gl/iKYTKypdhZtLxrzG6"
};
const cardapioLink = "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/Bulls+Cardapio.pdf";
const cardapio = [
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/1+HIST%C3%93RIA.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/2+COMBOS+_+VEG.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/3+STEAKS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/4+STARTERS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/5+STREET+FOOD.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/6+TO+EAT+WITH+FRINDS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/7+BURGUERS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/8+DESSERTS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/9+CAIPIS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/10+CLASSIC+_+SPECIAL.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/11+GIN+_+BIG+DRINKS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/12+JACK+DANIEL_S+_+SHOTS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/13+CHOPP+_+CERVEJA+_+ARTESANAIS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/14+SOFT+DRINKS+_+DESTILADOS.png",
    "https://bulls-beer-house-general-bucket.s3.sa-east-1.amazonaws.com/images/cardapio-25-05-2024/15+UNIDADES+_+INFLUENCER.png",
];

export {instagram, facebook, whatsapp, reserve, site, unitsAddress, cardapioLink, cardapio};