import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "./components/headers/light.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/VideoBackgroundHero";
import CTA from "components/features/ThreeColSimple.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import OPA from "pages/OPA.jsx";

import AboutUs from "pages/OPA/AboutUs.jsx";
import RestaurantMenu from "pages/OPA/RestaurantMenu.jsx";
import Units from "pages/OPA/Units.jsx";
import Contact from "pages/OPA/Contact.jsx";

import { cardapioLink, instagram as instagramLink, reserve as reserveLink } from "helpers/UtilLinks.js";

import { MdRestaurantMenu as CardapioIcon } from "react-icons/md";
import { FaWhatsapp as WhatsappIcon } from "react-icons/fa";
import { FiInstagram as InstagramIcon } from "react-icons/fi";

const StyledHeader = styled(Header)
`
  ${tw`p-4 pt-8 max-w-none w-full fixed top-0 left-0 z-50 bg-black transition-colors`}
  font-family: Inter,system-ui;
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-white hover:border-primary-100 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const CTA_cards = [
    {
      icon: <WhatsappIcon size={50} />,
      title: "Reservas via Whatsapp",
      description: "O Bulls trabalha com reservas todos os dias ! Não vai perder sua mesa em? Faz logo sua reserva com a gente ! 😄",
      url: reserveLink,
      target: "_blank",
      linkText: "Clica aqui e chama a gente !!"
    },
    {
      icon: <CardapioIcon size={50} />,
      title: "Cardápio",
      description: "Dá uma conferida em nosso cardápio para ficar por dentro de tudo que nós oferecemos ! 🤩",
      url: cardapioLink,
      target: "_blank",
      linkText: "Visualizar Cardápio"
    },
    {
      icon: <InstagramIcon size={50} />,
      title: "Instagram",
      description: "Não deixa de acompanhar a gente pelo instagram para não perder nenhuma promoção e acompanhar a programação da semana !!",
      url: instagramLink,
      target: "_blank",
      linkText: "Segue a gente lá !!"
    },
    // {
    //   icon: <PesquisaIcon size={50} />,
    //   title: "Pesquisa de Satisfação",
    //   description: "Já visitou nossa casa? Gostou do que a gente oferece? Compartilha com a gente ! 😜",
    //   url: "http://pesquisa.bullsbeerhouse.com",
    //   target: "_blank",
    //   linkText: "Responder Pesquisa"
    // }
  ];

//eslint-disable-next-line
export default (props) => {
  const sections = [
    {
        action: false,
        id: 'sobre',
        title: 'Sobre o Bulls',
        content: <AboutUs/>
    },
    {
        action: true,
        id: 'cardapio',
        title: 'Nosso Cardápio',
        content: <RestaurantMenu/>
    },
    {
        action: true,
        id: 'ambientes',
        title: 'Nossos Ambientes',
        content: <Units/>
    },
    {
        action: true,
        id: 'contato',
        title: 'Contato',
        content: <Contact/>
    }
  ];

  const navLinks= [
    <NavLinks key={1}>
      {props.routes && props.routes.map((link, i) => {
        let linkProps = {
          to: link.href,
        };

        if (link.hasOwnProperty('external') && link.external === true){
          linkProps.to = {
            pathname: linkProps.to
          };
        }

        if (link.hasOwnProperty('newtab') && link.newtab === true){
          linkProps.target = '_blank';
          linkProps.rel = 'noopener noreferrer';
        }

        return (
          <NavLink {...linkProps} key={i}>
            {link.text}
          </NavLink>
      )})}
    </NavLinks>
  ];

  const [activeSectionIndex, setActiveSectionIndex] = useState(null);
  const [sectionIndexes, setSectionIndexes] = useState({});
  const [goingToSection, setGoingToSection] = useState(false);
  const headerRef = React.createRef();
  const sectionRefs = {
    sobre: {
      index: 0,
      ref: useRef(null)
    },
    cardapio: {
      index: 1,
      ref: useRef(null)
    },
    ambientes: {
      index: 2,
      ref: useRef(null)
    },
    contato: {
      index: 3,
      ref: useRef(null)
    },
  };

  const toggleSection = sectionIndex => {
    if (activeSectionIndex === sectionIndex) setActiveSectionIndex(null);
    else setActiveSectionIndex(sectionIndex);
  };

  const scrollToView = (ref) => {
    let elCoord = ref.current.getBoundingClientRect().top + window.scrollY - 115;

    window.scroll({
      top: elCoord,
      behavior: "smooth",
      block: "start", 
      inline: "start"
    });
  };

  const updateHeaderBackground = () => {
    if(headerRef.current !== null){
      if(window.pageYOffset !== 0){
      headerRef.current.style.backgroundColor = 'black';
    } else {
      headerRef.current.style.backgroundColor = 'transparent';
    }
    }
  }

  useEffect(() => {
    if(props.hasOwnProperty('section')){
      if(props.section in sectionRefs){
        if(activeSectionIndex !== sectionRefs[props.section].index){
          toggleSection(sectionRefs[props.section].index);
          setGoingToSection(true);
        } else {
          scrollToView(sectionRefs[props.section].ref);
        }
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  }, [props]);

  useEffect(() => {
    if(props.section in sectionRefs){
      scrollToView(sectionRefs[props.section].ref);
    }
    setGoingToSection(false);
  }, [goingToSection]);

  useEffect(() => {
    updateHeaderBackground();

    window.removeEventListener('scroll', updateHeaderBackground);
    window.addEventListener('scroll', updateHeaderBackground, { passive: true });

    return () => window.removeEventListener('scroll', updateHeaderBackground);
  });

  return (
    <>
      <Helmet>
        <title>Bulls Beer House</title>
      </Helmet>
      <AnimationRevealPage disabled bgBlack>
        <StyledHeader ref={headerRef} links={navLinks} closeOnClickMobile={true} />
        <Hero />
        <CTA cards={CTA_cards} />
        <OPA sections={sections} sectionManager={{ activeSectionIndex: activeSectionIndex, toggleSection: toggleSection, sectionRefs: sectionRefs, sectionIndexes: sectionIndexes, setSectionIndexes: setSectionIndexes}} scrollToView={scrollToView} />
        <Footer navLinks={props.routes} />
      </AnimationRevealPage>
    </>
  );
};
