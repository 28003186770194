import React from "react";
import { Helmet } from "react-helmet";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import PNFContainer from "../components/misc/404NotFound.js";
import { Link } from 'react-router-dom';

import Header, { NavLink, NavLinks, LogoLink, DesktopNavLinks, NavToggle } from "../components/headers/light.js";

const Container = styled.div`${tw`relative -mx-4 -mt-4 bg-black text-gray-100 min-h-screen`}`;

const StyledHeader = styled(Header)
`
  ${tw`p-8 max-w-none w-full bg-black`}
  font-family: Inter,system-ui;
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-white hover:border-primary-100 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

//eslint-disable-next-line
export default (props) => {
  const navLinks = [
    <NavLinks key={1}>
      {props.routes &&
        props.routes.map((link, i) => {
          let linkProps = {
            to: link.href,
          };

          if (link.hasOwnProperty("external") && link.external === true) {
            linkProps.to = {
              pathname: linkProps.to,
            };
          }

          if (link.hasOwnProperty("newtab") && link.newtab === true) {
            linkProps.target = "_blank";
            linkProps.rel = "noopener noreferrer";
          }

          return (
            <NavLink {...linkProps} key={i}>
              {link.text}
            </NavLink>
          );
        })}
    </NavLinks>,
  ];

  return (
    <>
      <Helmet>
        <title>Ooops! - Página não Encontrada</title>
      </Helmet>
      <StyledHeader links={navLinks}/>
      <AnimationRevealPage disabled>
          <Container>
              <Link to="/">
                  <PNFContainer />
              </Link>
          </Container>
          <Footer navLinks={props.routes} tw="bg-black" />
      </AnimationRevealPage>
    </>
  )
}